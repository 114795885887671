<template>
  <div class="fragment">

    <div class="section-label large"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['packingTypes_Name'])"></div>
      {{$t('packingTypes_Name.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--33 custom-col--md-50"
      >
<!--           v-bind:class="{'custom-col&#45;&#45;50': !$route.query.papers}"-->
        <DefaultInput
            :label="$t('packingTypes_Name.localization_value.value') + ' (English)'"
            v-bind:class="{'ui-no-valid': CO.validation.name}"
            :error="CO.validation.name"
            :errorTxt="$t(`${CO.validationTranslate.name}.localization_value.value`)"
            v-model="CO.data.name"
        />
      </div>
    </div>



    <div class="section-label large"
         v-if="!$route.query.papers"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['packingTypes_Usage'])"></div>
      {{$t('packingTypes_Usage.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">

      <div class="custom-col custom-col--33 custom-col--sm-50"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['packingTypes_Price'])"></div>
        <InputSum
            :label="$t('packingTypes_Price.localization_value.value')"
            :icoType="'dollar'"
            v-bind:class="{'ui-no-valid': CO.validation.price}"
            :error="CO.validation.price"
            :errorTxt="$t(`${CO.validationTranslate.price}.localization_value.value`)"
            v-model="CO.data.price"
        />
      </div>
    </div>

    <div class="order-create__row custom-row" v-if="!isPhotoPrintSizes && !isPermanentVinylColor && !isHtvColor">

      <div class="custom-col custom-col--33 custom-col--sm-50 d-flex justify-content-between"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['packingTypes_packTypeBarcode'])"></div>
        <DefaultInput
            class="w-100"
            :label="$t('packingTypes_packTypeBarcode.localization_value.value')"
            v-bind:class="{'ui-no-valid': CO.validation.barcode}"
            :error="CO.validation.barcode"
            :errorTxt="$t(`${CO.validationTranslate.barcode}.localization_value.value`)"
            v-model="CO.data.barcode"
        />

<!--        <MainButton class="white-space-nowrap brown ml-3"-->
<!--                    :value="$t('common_print.localization_value.value')"-->
<!--                    @click.native="printBarcode"-->
<!--        >-->
<!--        </MainButton>-->

      </div>


    </div>

    <div class="order-create__row custom-row" v-if="!isPhotoPrintSizes && !isPermanentVinylColor && !isHtvColor">

      <div class="custom-col d-flex justify-content-between"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['packingTypes_product'])"></div>
        <DefaultSelect
            class="w-100"
            :options="optionsProduct"
            :optionsLabel="'name_for_fbm'"
            :label="$t('packingTypes_product.localization_value.value')"
            v-bind:class="{'ui-no-valid': CO.validation.product}"
            :error="CO.validation.product"
            :errorTxt="$t(`${CO.validationTranslate.product}.localization_value.value`)"
            :selected="CO.data.product"
            @change="changeProduct"
        />
      </div>
    </div>


    <div class="order-create__row custom-row"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['packingTypes_Enable'])"></div>
      <div class="custom-col">
        <DefaultCheckbox
            :label="$t('packingTypes_Enable.localization_value.value')"
            :value="CO.data.enable"
            @input="(val) => {CO.data.enable = val}"
        />
      </div>
    </div>

    <template
        v-if="CO.data.type === CO.data.types.dtg_printing.name">
      <div class="order-create__row custom-row">
      <div class="custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['packingTypes_Sizes'])"></div>
        <DefaultCheckbox
            :label="$t('packingTypes_Sizes.localization_value.value')"
            :value="isSizes || isSizesChecked"
            @input="changeSizes"
        />
      </div>
      </div>

      <div class="order-create__row custom-row custom-multi" v-if="isSizes || isSizesChecked">
        <div class="custom-col custom-col--33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['packingTypes_Sizes'])"></div>
          <MultiSelect
              class="white mb-3"
              :options="possibleVariants"
              :label="$t('packingTypes_Sizes.localization_value.value')"
              :optionsLabel="'name'"
              :selected="CO.data.variations"
              :searchable="true"
              @change="val => {CO.data.variations = val}"
          />
        </div>
      </div>
    </template>


  </div>
</template>

<script>
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import InputSum from "@/components/UI/inputs/InputSum/InputSum";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import MultiSelect from "@/components/UI/selectiones/MultiSelect/MultiSelect";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
export default {
  name: "CustomizationOffer",
  components: {DefaultSelect, MultiSelect, DefaultCheckbox, InputSum, DefaultInput},

  props: {
    CO: Object,
    orderData: Object,
  },

  computed: {
    isSizes() {
      return this.CO.data.variations && this.CO.data.variations.length > 0
    },
    possibleVariants() {
      return this.orderData?.possible_variants || []
    },
    isPhotoPrintSizes() {
      return this.$route.params.type === 'photo_printing_size'
    },
    isPermanentVinylColor() {
      return this.$route.params.type === 'permanent_vinyl'
    },
    isHtvColor() {
      return this.$route.params.type === 'htv'
    },
  },

  data() {
    return {
      isSizesChecked: false,
      optionsProduct: [],
    }
  },

  mounted() {
    this.$store.dispatch('fetchSpecialProducts', '?page=0&limit=100000').then(response => {
      this.optionsProduct = this.getRespPaginateData(response)
    })
  },

  methods: {
    changeSizes(val) {
     this.isSizesChecked = val
      if(!val) {
        this.CO.data.variations = []
      }
    },

    changeProduct(val) {
      this.CO.data.product = val
    },
  }

}
</script>

<style scoped>
.custom-multi >>> .multi-select.white .v-select:not(.vs--single) .vs__selected-options .vs__selected .vs__deselect {
  top: 6px;
}
</style>